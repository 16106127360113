
@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
    font-family: 'logo';
    src: url(./assets/fonts/Meddon-Regular.ttf);
}
@font-face {
    font-family: 'anti';
    src: url(./assets/fonts/AnticDidone-Regular.ttf);
}

@font-face {
    font-family:'urban' ;
    src: url(./assets/fonts/Urbanist-VariableFont_wght.ttf);
}

.fullscreen-carousel {
    width: 100vw;
    height: 100vh;
}

.carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-navigation {
    position: absolute;
    bottom: 4%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 50;
    display: flex;
    gap: 2px;
}

.navigation-dot {
    width: 4px;
    height: 1px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    transition: all 0.3s ease;
}

.navigation-dot.active {
    width: 8px;
    background-color: #fff;
}
