@keyframes slow-spin {
    0% {
      transform:  translateX(0px);
    }
    50%{
        transform: translateX(60px);
    }
    100% {
     transform: translateX(0px);
    }
  }
  
  .slow-spin {
    animation: slow-spin 5s linear infinite;
  }

  .inner-con {
    width: 80%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 60px;
  }
  .gals{
    position: relative;
    width: fit-content;
    transition: all 1s ease-in-out;

  }
  .gals::after{
    position: absolute;
    content: '';
    background-color:rgb(0,0,0,0.5);
    inset: 0;
  }
  .gals:hover::after{
    background-color: transparent;
  }

  .anims{
    animation: anims-testi 5s infinite;
  }
  @keyframes anims-testi {
    0%{
        scale: 0.8;
    }
    100%{
        scale: 1;
    }
  }

  .testi-gal{
    animation: testi 5s infinite ;
    scale: 1
  }
  @keyframes testi {

    0%{
        scale:0.8 ;
    }
    25%{
        scale: 1;
    }
    100%{
        scale: 1;
    }

    
  }